import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: calc(100% - 80px);
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 75px;
  padding-bottom: 75px;
  background-color: white;

  h1 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 200;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    color: #585858;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    margin-bottom: 19px;
  }

  .inner {
    max-width: 940px;
    width: 100%;
    margin: 0 auto;
    
    display: flex;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    .copy {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 26px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      color: #585858;
      b {
        font-weight: bold;
        color: #01426A;
      }
      .spacer {
        height: 18px;
      }
    }

  }
`;

function Simple(props) {
  return (
    <Wrapper>
      <h1>{props.title}</h1>
      <div className="inner">
        <div className="wrap-img">
          <img src={props.image} />
        </div>
        <div className="copy" dangerouslySetInnerHTML={{ __html: props.html }}></div>
      </div>
    </Wrapper>
  );
}

export default Simple;